body {
  margin: 0px;
  padding: 28px;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito Sans', 'sans-serif';
    -webkit-font-smoothing: antialiased;
}

.rgt-footer-clear-selection-button > svg{
  display: none;
}

/*
.rgt-cell-header {
  font-weight: 500 !important;
}

.rgt-footer-clear-selection-button > svg{
  display: none;
}

.rgt-header-container{
  border: var(--rgt-border);
    
  
}


.rgt-cell-header-inner{
  background: #EAF3DD !important;
  border-right: 1px solid #e1e1e1 !important;
  /* border-left: 1px solid #e1e1e1 !important; */
  /*
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;

}

.rgt-text-truncate{
  font-weight: inherit !important;
}
*/
/*

.rgt-footer {
  /* display: flex !important;
  position: fixed;
  bottom: 0;
  width: 94vw !important;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-weight: 500;
  background: var(--rgt-background-color);
  z-index: 1;
  border-top: var(--rgt-border);
  overflow-x: auto;
  overflow-y: hidden; */
/*  position: relative !important;
  bottom: 0 !important;
  width: 94vw !important;
  top: 50 !important;
} */

/* #root {
  margin: 0px;
  padding: 2vw;
  -moz-osx-font-smoothing: grayscale;
  
}

.demo {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* .rgt-cell {
  min-height: 40px !important;
  border-right: 1px solid #f7f7f7 !important;
  padding: 5px !important;
} */


/* .marketo_ui ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.marketo_ui ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.marketo_ui ::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
} */

.tableWrapper {
  height: 100%;
  padding: 20px;
  overflow: auto;
  flex: 1;
  margin: 0;
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

/* .rgt-cell-header-inner  {
  background: #ecf7fe !important;
} */

/* .rgt-cell-header {
  background: #ecf7fe !important;
} */

/* .rgt-footer {
  position: relative;
  bottom: 0;
  width: 93%;
  top: 50;
} */

.rgt-row-selected {
  background: #c8e0a8;
}

.rgt-row-hover {
  background: #f5f5f5;
}

.Toastify__toast-container {

  min-width: 320px;

}

.Toastify__toast--success {
  background: #edf7ed !important;
  color: #4CAF50;

}

.Toastify__close-button--success {
  color: #4CAF50 !important;
  opacity: 1;
}

.Toastify__toast--warning {
  background: #fff6d1 !important;
  color: #f5c60a;

}

.Toastify__close-button--warning {
  color: #f5c60a !important;
  opacity: 1;
}


.Toastify__toast--error {
  background: #fceceb !important;
  color: #F44336;

}

.Toastify__close-button--error {
  color: #F44336 !important;
  opacity: 1;
}

/* .MuiInputBase-root  {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  border-radius: 0px !important;
  border-bottom: solid 1px #eee !important;
}

 .autoCompleteInput {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  border-radius: 0px !important;
  border-bottom: solid 1px #eee !important;
} */

 .MuiAutocomplete-root {
  /* width: 100% !important;
  border-bottom: solid 1px #eee !important; */
  /* border: none !important;
  outline: none !important;
  border-radius: 0px !important;
  border-bottom: solid 1px #eee !important; */
}

::-webkit-scrollbar-track {
  background-color: #FFFFFF!important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 6px;
  background-color: #FFFFFF!important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.3);
  border-radius: 4px;
}