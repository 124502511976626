#red_color {
    color: red !important;
}

.pageHead {
    vertical-align:middle !important;
    margin: 0 !important;
    font-weight: 400;
}

.btn {
    min-width: 150px !important;
    margin-left: 12px !important;
    /* margin-right: 8px !important; */
    
}


.overlayLoader{
    z-index: 1000 !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

/* 
.light-background {
    border: 1px solid #109CF1 !important;
    box-shadow: 0px 1px 5px 0px #0000001F;

    box-shadow: 0px 2px 2px 0px #00000024;

    box-shadow: 0px 3px 1px -2px #00000033;
    background: #FFFFFF;
    border-radius: 4px;

}
.light-background:disabled {
    border: 1px solid #109CF1 !important;
    color: 109CF1;
    background: #f3f3f3;
    box-shadow: 0px 0px 0px 0px #0000001F;


}

.dark-background{
    box-shadow: 0px 1px 5px 0px #0000001F;

    box-shadow: 0px 2px 2px 0px #00000024;
    
    box-shadow: 0px 3px 1px -2px #00000033;    
    background: #109CF1 !important;
    color: #FFFFFF !important;
    border-radius: 4px; 
}

.dark-background:disabled{

    background: #109bf1b9 !important;
    color: #dbdbdb !important;
    box-shadow: 0px 0px 0px 0px #0000001F;

}

.dark-background:hover{
 
    background: #109bf1c7 !important;

} */

.rgt-wrapper{
    overflow: unset !important;
}
.button-array{
    
    text-align: right;
    align-items: right;
    margin-bottom: 26px;
}

.headSection{
    /* display:inline-block; */
    display: flexbox;
    justify-content: space-between;
    
    margin-bottom: 20px ;
    width: 100%;
    /* text-align: center; */
    vertical-align:middle;
    

}

.becomeAnchorTagIfIdPresent_anchor{
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: #109CF1;
}

.mediumSize{
    font-size: 16px;
    font-weight: inherit;
}

.anchorTag {
    vertical-align:middle;
    color: #109CF1;
    padding: 5px;
}

.right {
    height: 100%;
    /* float: right;  */
    display:inline-block;
}

.left {
    display:inline-block;
    /* float: left; */
}

.warnButton{
    background-color: #F7685B !important;
    color: #FFFFFF !important;
}

.warnButton:hover{
    background-color: #ff301d !important;
    color: #FFFFFF !important;
}

